import React from 'react'
import "../../style/middle.css"
import Logo from '..//../../assets/Group 143 (1).svg'

const middleCenter = () => {
  return (
    <div className='middle-center'>
      <div className="section1">
        <p>About Us</p>
      </div>
      <div className="section2">
        <p>The Perfect Travel Place For <br></br> You & Your Family</p>
      </div>
      <div className="section3">
        <p>how travel can be one of the best tools for personal growth. We share <br></br> some of the life lessons we've learned on the road that has provided <br></br> us insight into empathy, mindfulness, meditation, comfort zones,<br></br> two-way storytelling,
           being present, connection and more.</p>
      </div>
     <div className="section30">
     <div className="section4">
        
        <p>10M+</p>
        <span>Visitors</span>
      </div>
      <div className="section5">
        <p>109</p>
        <span>Hotels</span>
      </div>
      <div className="section6">
        <p>29</p>
        <span>Food Shop</span>
      </div>
      <div className="section7">
       <p>18</p> 
       <span>Related Spots</span>
      </div>
     </div>
      <div className="section8">
        <img src={Logo} alt=""  />
      </div>
    </div>
  )
}

export default middleCenter