import React from "react";
import "../../style/Header.css";
import Logo from "..//../../assets/Logo (4).svg";
const Header = () => {
  return (
    <div className="header-main">
      <div className="header-logo">
        <img src={Logo} alt="" />
        <div className="header-menu">
          <p>Home</p>
          <p>About</p>
          <p>Packages</p>
          <p>Contact Us</p>
          <p>FAQ</p>
          <div className="header-button">
            <button>Register</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
