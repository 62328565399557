import React from 'react';
import logo from '..//../../assets/Logo.svg';
import insta from '..//../../assets/instagramm.svg';
import facebook from '..//../../assets/facebook.svg';
import pay from '..//../../assets/pey.svg';
import '../../style/main.css';

const LastPassBlock = () => {
  return (
    <div className='footer'>
  <div className='logo'>
    <img src={logo} alt="" />
  </div>
  <div className='menu'>
    <p>Home</p>
    <p>About</p>
    <p>Packages</p>
    <p>Contact Us</p>
    <p>FAQ</p>
  </div>
  <div className='logo-inst-face-pay'>
    <img src={facebook} alt="" />
    <img src={insta} alt="" />
    <img src={pay} alt="" />
    <div className='line'></div>
  </div>
  <div className='line'></div>
  <div className='goodbye'>
      <span> © 2021. All copyrights reserved by RH Agency</span>
    </div>
</div>

  )
}

export default LastPassBlock
