import React from "react";
import DoubleImg from "../../../assets/Group 143.svg";
import "../../style/HeaderMiddle.css";
const HeaderMiddle = () => {
  return (
    <div className="middle">
      <div className="middle-block">
        <img src={DoubleImg} alt="" />
        <div className="middle-text">
          <p>Book Now</p>
          <div className="middle-main-text">
            <p>
              Let’s Enjoy Your Trip
              <br /> with TripGoal
            </p>
            <div className="middle-op-text">
              <p>
                Thinking of taking a break from every day's busy life? Planning
                to go
                <br /> out of the country with your loved ones to have some fun
                and quality <br />
                time in a cost-effective way?
              </p>
              <div className="middle-button">
                <button>Start Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="request">
        <div className="request-block">
          <p>Location</p>
          <p>Your Category</p>
          <p>Total Person</p>
          <div className="request-op">
            <p>Select</p>
            <p>Product</p>
            <p>Stalker</p>
          </div>
          <div className="request-button">
            <button>Book Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMiddle;
