import React from "react";
import "../../style/HeaderCenter.css";
import Chat from "../../../assets/Chat.svg";
import Calendar from "../../../assets/Calendar.svg";
import Star from "../../../assets/Star.svg";
const HeaderCenter = () => {
  return (
    <div className="center">
      <div className="center-block">
        <p>3 steps for the perfect trip</p>
        <span className="span">Find travel perfection</span>
        <b>
          Naturally head of the class whenit comes to luxury travel
          planning,because
          <br /> we do more homework than anyone else
        </b>
        <div className="card1">
          <div className="card1-main">
            <img src={Chat} alt="" />
            <p>Tell us what you want to do</p>
            <span>
              Fill out a 2-minute questionnaire
              <br /> about how you like to travel
            </span>
          </div>
        </div>
        <div className="card2">
          <div className="card2-main">
            <img src={Calendar} alt="" />
            <p>Share your travel preference</p>
            <span>
              It all happends online,We recommend
              <br /> everything to your vision
            </span>
          </div>
        </div>
        <div className="card3">
          <div className="card3-main">
            <img src={Star} alt="" />
            <p>We’ll give you recommendations</p>
            <span>
              Once you’re happy with your final plan,
              <br /> We handle everything for you
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCenter;
