import "./App.css";
import Header from "./components/page/Block2/HeaderMiddle";
import HeaderMiddle from "./components/page/Block1/Header";
import HeaderCenter from "./components/page/Block3/HeaderCenter";

import Oibek_part from "./components/security/bl1/bl2/Oibek_2part";
import Oibek_2part from "./components/security/bl1/Oibek_part";

import MiddleCenter from './components/section/block12/MiddleCenter';
import MiddleBlockTravel from './components/section/block21/MiddleBlockTravel';
import LastBlocks from './components/footer/bl122/LastBlocks';
import LastMidlleBlock2 from './components/footer/bl211/LastMidlleBlock2';
import LastPassBlock from './components/footer/bl333/LastPassBlock';

function App() {
  return (
    <div className="App">
      <HeaderMiddle />
      <Header />
      <HeaderCenter />
      <Oibek_part/>
      <Oibek_2part/>
      <MiddleCenter/>
      <MiddleBlockTravel/>
      <LastBlocks/>
      <LastMidlleBlock2/>
      <LastPassBlock/>
    </div>
  );
}

export default App;
