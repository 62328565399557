import React from 'react';
import '../../style/main.css';
import LevoCortinaNepom from '..//../../assets/levcortinaneponblok1.svg';
import LevoCortinaPom from '..//../../assets/levcortinaneponblok1.svg';
import CenCortina from '..//../../assets/cencortinaponblok1.svg';
import PravCortinaPon from '..//../../assets/pravcortinaponblok1.svg';
import PravCortinaNepon from '..//../../assets/pravcortinaneponblok1.svg';

const LastBlocks = () => {
  return (
    <div className='parentblock'>
      <div className='childblock1'>
        <p className='instagram-line3'>Social Feed</p>
        <span className='instagram-line1'>Check Our Instagram</span>
        <span className='instagram-line2'>Profile</span>
        <button className='buttom1'>Follow Us</button>
      </div>
      <div className='childblock2'>
          <img src={LevoCortinaNepom} alt="" />
          <img src={LevoCortinaPom} alt="" />
          <img src={CenCortina} alt="" />
          <img src={PravCortinaPon} alt="" />
          <img src={PravCortinaNepon} alt="" />
      </div>
    </div>
  )
}

export default LastBlocks
