import React from 'react'
import "../../../style/Oibek_2part.css";
import Image6 from "../../../../assets/image 6.svg";



const Oibek_2part = () => {
    return (
      <div className='Oibek_2part'>
          <div className='part2_img'><img src={Image6} alt="" /></div>
          <div className='img_part2'></div>
          <div className='oibek2part_text'>
              <h4>Take a Tour</h4>
              <h2>Discover Our Travel<br></br> Guideline</h2>
              <p>For curious travelers who want to connect locally and go<br/> deeper into a place. Our Experiential Travel Guides highlight<br/> offbeat and local experiences that help you.</p>
              <button>Start Now</button>
          </div>
      </div>
    )
  }
  
  export default Oibek_2part