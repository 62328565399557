import React from 'react'
import '../../style/main.css';
import woman from '..//../../assets/woman.svg'

const LastMidlleBlock2 = () => {
  return (
    <div className='Oneblock1'>
       <div className='Oneblock2'>
        <p className='p-text1'>Contact Us</p>
        <p className='p-text'>Do You Have Any</p>
        <span>Questions?</span>
        <button className='buttom2'>Contact Us</button>
       </div>
       <div className='Oneblock3'>
          <img src={woman} alt="" />
       </div>
    </div>
  )
}

export default LastMidlleBlock2
