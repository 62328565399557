
import React from 'react'
import "../../style/Oibek_part.css";
import Frame5 from "..//../../assets/Frame 5.svg";
import Image5 from "..//../../assets/image 5.png";
import Frame7 from "..//../../assets/Frame 7.png";


const Oibek_part = () => {
    return (
      <div>
          <div className='block_oibek'>
          <div className='block1'>
            <div className='block1_text'>
              <h3>Location</h3>
              <h2>Suggested Location</h2>
              <p>Travel is my life. Since 1999, I’ve been traveling around the world nonstop. <br></br>If you also love travel, you’re in the right place!
              </p>
            </div>
  
            <div className='block1_btn'>
              <button></button>
              <button></button>
            </div>
            <div>
              <div className='block1_img'>
              <div>
                <img src={Frame5}></img>
              </div>
              <div>
                <img src={Image5}></img>
                </div>
              <div>
                <img src={Frame7}></img>
                </div>
              </div>
  
            </div>
          </div>
          
        </div>
      </div>
     
    )
  }
  
  export default Oibek_part