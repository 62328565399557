import React from 'react'
import '../../style/MiddleBlockTravel.css'
import Logo3 from '..//../../assets/Vector.png'
import Logo4 from '..//../../assets/quotation 1.svg'
import Logo5 from '..//../../assets/Group 145.svg'
import Logo6 from '..//../../assets/image 12.svg'
import Logo7 from '..//../../assets/image 20.svg'
import Logo8 from '..//../../assets/image 14.svg'

const MiddleBlockTravel = () => {
  return (
    <div className='MiddleBlockTravel'>
        <div className="section9">
            <p>Testimonials</p>
        </div>
        <div className="section10">
            <p>What Our Customer Say About Us</p>
        </div>
        <div className="section11">
            <p>What customers says about our service and guideline. Customers compliment <br></br> is most important to upgradation of bussiness service </p>
        </div>
       
       
        <div className="section14">
            <img src={Logo3} alt=""  />
        </div>
        
        <div className="section16">
            <img src={Logo5} alt=""  />
        </div>
        <div className="section17">
            <p> I have traveled and explored several destinations using this <br/> platform, no doubt it is highly recommended for traveling <br/> because it is comfortable and the service is great.</p>
            <span>Adam L. Brian</span>
            <b>Traveller</b>
            <img src={Logo4} alt="" />
        </div>
        <div className="section18">
            <img src={Logo6} alt=""  />
        </div>
        <div className="section19">
            <img src={Logo7} alt=""  />
        </div>
        <div className="section20">
            <img src={Logo8} alt=""  />
        </div>
    </div>
  )
}

export default MiddleBlockTravel